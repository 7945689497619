import * as React from 'react'
import Layout from '../../layout'
import SelectGroupPage from '../../pagesComponents/rfid/group'

const SelectGroup = () => {
	return (
		<Layout title="Select Group To Link Your Wristband">
			<SelectGroupPage />
		</Layout>
	)
}

export default SelectGroup
